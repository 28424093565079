// eslint-disable-next-line import/no-extraneous-dependencies
import { Notifier } from "@airbrake/browser";
import { URL } from "url";

export const airbrakeClient = new Notifier({
  projectId: process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID,
  projectKey: process.env.NEXT_PUBLIC_AIRBRAKE_KEY,
  environment: process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV,
});

function isAllowedHost(urlString, allowedHosts) {
  try {
    const url = new URL(urlString);
    return allowedHosts.includes(url.host);
  } catch (e) {
    return false;
  }
}

airbrakeClient.addFilter(notice => {
  const userAgent = notice.context?.userAgent?.toLowerCase() || "";
  const skipErrorMessage = Boolean(
    notice.errors.filter(err =>
      // identify chrome extension error message
      err.message.includes("Extension context invalidated")
    )[0]
  );

  if (
    isAllowedHost(notice.context?.url || "", [
      "imagizer",
      "securepubads.g.doubleclick.net",
      "c.bannerflow.net",
    ]) ||
    userAgent.includes("googlebot") ||
    userAgent.includes("claritybot") ||
    userAgent.includes("bingbot") ||
    userAgent.includes("facebookexternalhit") ||
    skipErrorMessage
  )
    return null;
  return notice;
});
airbrakeClient.addFilter(notice => {
  // eslint-disable-next-line no-param-reassign
  notice.context.sourceMaps = {
    "https://**/articles/**.map":
      "https://**/_next/static/chunks/pages/articles/[slug]-*.js.map",
    "https://**/authors/**.map":
      "https://**/_next/static/chunks/pages/authors/[slug]-*.js.map",
    "https://**/best-in-travel/**.map":
      "https://**/_next/static/chunks/pages/best-in-travel/[category]-*.js.map",
    "https://**/ebook-offer/**.map":
      "https://**/_next/static/chunks/pages/ebook-offer/[slug]-*.js.map",
  };
  return notice;
});
