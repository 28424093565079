import React, { Fragment } from "react";
import cn from "classnames";
import { Popover, Transition } from "@headlessui/react";
import { ImgixNextImage } from "@components/imgixNextImage";
import { useUser } from "@auth0/nextjs-auth0/client";
import { Login } from "@components/login";
import { tagular } from "@cohesion/tagular";

import { CloseX, Bookmark, SignOut, Gear, OpenBook } from "@icons";
import { LogoGlobe } from "@icons/logos";

export function ProfilePanel() {
  const userSession = useUser();
  const { user, isLoading } = userSession || {};
  const { nickname = "unknown", picture = null } = user || {};

  const pictureUrl = !picture?.includes("gravatar") ? picture : null;

  function handleTracking(open) {
    document.documentElement.classList.toggle("no-scroll-mobile", !open);
    if (open) {
      // tracking event
      const openProfileEvent = new Event("GlobalHeader/openProfile");
      document.dispatchEvent(openProfileEvent);
    }
    tagular("click", {
      actionOutcome: open ? "CLOSE" : "INTERNALLINK",
      outboundUrl: "null",
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL NAV BAR",
        text: open ? "PROFILE-X" : "PROFILE-NAV",
      },
    });
  }

  const handleSubscribeTagularEvent = event => {
    const text = event.target.innerText.toUpperCase();
    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "/api/auth/login",
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL NAV BAR",
        text,
      },
    });
  };

  const handleSubmenuTagularEvent = event => {
    const text = event.currentTarget.getAttribute("data-title");
    const url = event.currentTarget.getAttribute("data-url");

    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: url,
      webElement: {
        location: "HEADER",
        elementType: "LINK",
        position: "GLOBAL SUB MENU",
        text,
      },
    });
  };

  return (
    <Popover
      className={cn("relative", {
        "flex items-center": !user && !isLoading,
      })}
    >
      {({ open }) => (
        <>
          {!user && !isLoading && (
            <Login
              action="signin"
              className="btn btn-primary focus:outline-none"
              onClick={handleSubscribeTagularEvent}
            >
              Sign in
            </Login>
          )}
          {user && !isLoading && picture && (
            <Popover.Button
              className={cn(
                "relative flex items-center self-center w-10 h-full text-sm font-semibold text-black hover:text-blue",
                open ? "text-blue" : ""
              )}
              onClick={() => handleTracking(open)}
            >
              <ImgixNextImage
                src={pictureUrl}
                alt={`picture of ${nickname}`}
                className={cn(
                  "rounded-full h-10 w-full md:translate-y-0",
                  "flex left-4 lg:left-0 lg:hover:opacity-80 text-black !mr-0 lg:max-w-[112px]"
                )}
                type="user"
                width={40}
                height={40}
                imgixParams={{
                  auto: "format",
                  fit: "crop",
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                className={cn(
                  "absolute left-0 right-0 -bottom-1 mx-auto translate-y-full text-white",
                  { hidden: !open }
                )}
              >
                <path
                  d="M8.49348 0L17.9794 15.5138H0.0615234L8.49348 0Z"
                  fill="currentColor"
                />
              </svg>
            </Popover.Button>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={cn(
                "w-full md:w-content h-full md:h-auto max-w-4xl @5xl:max-w-4xl @7xl:max-w-none fixed md:absolute top-0 md:top-full right-0 md:right-full md:translate-x-20 z-1 md:mt-4 md:rounded-3xl overflow-hidden bg-white md:rounded md:drop-shadow-sm"
              )}
            >
              <nav className="@7xl:w-content @5xl:min-w-64">
                <div className="lg:px-8 lg:pt-2 lg:pb-10 lg:space-y-5">
                  <div className="flex items-center justify-between p-2 border-b border-black-200 md:hidden">
                    <LogoGlobe
                      width="25"
                      height="25"
                      className="text-blue"
                      aria-hidden="true"
                    />
                    <Popover.Button
                      className="inline-flex items-center justify-center p-2 text-black"
                      aria-label="Close search"
                      onClick={() => handleTracking(open)}
                    >
                      <span className="sr-only">Close menu</span>
                      <div className="bg-black-100 p-2 rounded-full">
                        <CloseX width="16" height="16" aria-hidden="true" />
                      </div>
                    </Popover.Button>
                  </div>
                  <a
                    href="/profile/saves"
                    className="flex p-4 lg:p-0 border-b border-black-200 gap-x-2.5 lg:border-none items-center group-hover:bg-white hover:text-blue text-black transition-colors"
                    data-title="saves"
                    data-url="/profile/saves"
                    onClick={handleSubmenuTagularEvent}
                  >
                    <Bookmark className="hover:text-blue" /> Saves
                  </a>
                  <a
                    href="/profile/library"
                    className="flex p-4 lg:p-0 border-b border-black-200 gap-x-2.5 lg:border-none items-center hover:bg-white hover:text-blue text-black transition-colors"
                    data-title="library"
                    data-url="/profile/library"
                    onClick={handleSubmenuTagularEvent}
                  >
                    <OpenBook className="hover:text-blue" />
                    eLibrary
                  </a>
                  <a
                    href="/profile/settings"
                    className="flex p-4 lg:p-0 border-b border-black-200 gap-x-2.5 lg:border-none items-center hover:bg-white hover:text-blue text-black transition-colors"
                    data-title="settings"
                    data-url="/profile/settings"
                    onClick={handleSubmenuTagularEvent}
                  >
                    <Gear className="hover:text-blue shrink-0" />
                    Account settings
                  </a>
                </div>
                <a
                  href="/api/auth/logout"
                  className="flex bg-black-100 gap-x-2.5 items-center p-4 font-semibold text-black hover:bg-blue hover:text-white"
                  onClick={handleSubmenuTagularEvent}
                  data-url="/api/auth/logout"
                  data-title="LOGOUT"
                >
                  <SignOut className="hover:text-white" />
                  Sign out
                </a>
              </nav>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
