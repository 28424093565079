import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import {
  ConsoleInstrumentation,
  ErrorsInstrumentation,
  FetchTransport,
  LogLevel,
  WebVitalsInstrumentation,
  initializeFaro as coreInit,
  getWebInstrumentations,
  defaultMetas,
  pageMeta,
} from "@grafana/faro-web-sdk";

const appName = "dotcom-frontend-2";
export const browserInstrumentation = ({ getReactPageId }) => {
  const faro = coreInit({
    app: {
      name: appName,
      environment:
        process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "production"
          ? "production"
          : "development",
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new WebVitalsInstrumentation(),
      new ErrorsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [
          LogLevel.TRACE,
          LogLevel.INFO,
          LogLevel.DEBUG,
          LogLevel.LOG,
        ],
      }),
      new TracingInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url: "/api/logs",
      }),
    ],
    metas: [
      ...defaultMetas,
      {
        page: {
          ...pageMeta()?.page,
          id: getReactPageId(),
        },
      },
    ],
  });

  try {
    faro?.api.pushLog(["Faro was initialized"]);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error initializing Faro", error);
  }
};
